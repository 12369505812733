import { editMenu } from 'pmt-modules/orderMenu'
import { createOrderProductFromProduct, openProduct } from 'pmt-modules/orderProduct'
import { createMiddleware } from 'pmt-modules/redux'
import { createSuggestionFromItem, showSuggestionDialog } from 'pmt-modules/suggestion/actions'

import { CartAction } from './actions'

/**
 * Intercepts only add to cart actions BEFORE
 * this is used to interact with cart before adding items (e.g. suggestions)
 */
export const addToCartMiddleware = createMiddleware(
  [
    CartAction.ADD_TO_CART_FROM_SHORTCUT,
    CartAction.ADD_ORDER_PRODUCT_TO_CART,
    CartAction.ADD_ORDER_MENU_TO_CART,
  ],
  ({ getState, dispatch, next, action }) => {
    // check for skipSuggestion to ensure we don't reopen suggestion while adding current item
    if (!action.options || !action.options.skipSuggestion) {
      const item = action.item
        ? createOrderProductFromProduct(action.item)
        : action.orderProduct || action.orderMenu

      if (item && item.hasSuggestion && !item.isSuggestedItem) {
        // create suggestion store for current item
        dispatch(createSuggestionFromItem(item))

        dispatch(showSuggestionDialog(item))
        // we are in suggestion modal, we don't want to add the item yet, so we stop propagation
        return createMiddleware.STOP_PROPAGATION
      }
    }
  }
)

const openEditItemMiddleware = createMiddleware(CartAction.EDIT_ITEM, ({ dispatch, action }) => {
  const item = action.item

  if (item.isProduct) {
    dispatch(
      openProduct(item, {
        showDialog: true,
        isMenuMode: false,
        restaurantId: action.options.restaurantId || null,
        orderProduct: action.orderProduct,
        isEditMode: true,
        fromRoute: action.options.fromRoute,
      })
    )
  } else if (item.isMenu) {
    dispatch(
      editMenu(item, {
        restaurantId: action.options.restaurantId,
        fromRoute: action.options.fromRoute,
      })
    )
  }
})

export const cartMiddlewares = [addToCartMiddleware, openEditItemMiddleware]
